import qs from "qs";

const {
  location: { search },
} = window;

export const generateLink = (): string => {
  //   if (!search) return; // need to send an error that we don't have

  const query = search.replace("?", "");
  let params = qs.parse(query);
 
  //   if (!params.code) return; // need to return an error that we don't have needed code to generate the link

  const baseURL = "https://link.qumata.com/";
  const newURL = baseURL + params.code;

  return newURL;
};
