import { FunctionComponent } from "react";
import { Route, Routes } from "react-router-dom";
import { ROUTES } from "utils/routes";
import styles from "./AppNavigator.module.scss";

import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";

import Home from "screens/Home/Home";

const AppNavigator: FunctionComponent = () => (
  <>
    <div className={styles.wrapper}>
      <div className={styles.top}>
        <Header />
        <Routes>
          <Route path={ROUTES.home} element={<Home />} />
        </Routes>
      </div>

      <div className={styles.bottom}>
        <Footer />
      </div>
    </div>
  </>
);

export default AppNavigator;
