import React from "react";
import { useTranslation } from "react-i18next";
import CONSTANTS from "utils/constants";
import images from "utils/images";
import styles from "./ContactUsButton.module.scss";

export const openSupportEmail = () => {
  window.open(`mailto:${CONSTANTS.supportEmail}`);
};

const ContactUsButton: React.FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <div onClick={openSupportEmail} className={styles.wrapper}>
      <div className={styles.smallWrapper}>
        <img
          alt="Contact us"
          src={images.contactUsPurple}
          className={styles.smallIcon}
        />
      </div>
      <div className={styles.bigWrapper}>
        <span>{t("home.contact-us")}</span>
        <img
          alt="Contact us"
          src={images.contactUsWhite}
          className={styles.bigIcon}
        />
      </div>
    </div>
  );
};

export default ContactUsButton;
