import styles from "./Footer.module.scss";

const Footer = () => {
  return (
    <div className={styles.footerContainer}>
      Powered by&nbsp;<strong className={styles.strong}>Qumata</strong>
    </div>
  );
};

export default Footer;
