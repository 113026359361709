import ContactUsButton from "components/ContactUsButton/ContactUSButton";
import { FunctionComponent } from "react";
import styles from "./Header.module.scss";

const Header: FunctionComponent = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.rightContainer}>
        <ContactUsButton />
      </div>
    </div>
  );
};

export default Header;
