import "./normalize.css";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import AppNavigator from "components/AppNavigator/AppNavigator";

function App() {
  return (
    <BrowserRouter>
      <AppNavigator />
    </BrowserRouter>
  );
}

export default App;
