import { FunctionComponent } from "react";
import { ButtonProps, ButtonTypes } from "components/Button/Button";
import BigButton from "components/BigButton/BigButton";

const ButtonContent: FunctionComponent<ButtonProps> = ({
  type,
  text,
  icon,
  disabled,
}) => {
  const renderContent = () => {
    switch (type) {
      case ButtonTypes.big:
        return <BigButton text={text} icon={icon} disabled={disabled} />;
    }
  };

  return <>{renderContent()}</>;
};

export default ButtonContent;
