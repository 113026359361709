import ButtonContent from "components/ButtonContent/ButtonContent";
import { FunctionComponent } from "react";
import styles from "./Button.module.scss";

export enum ButtonTypes {
  small = "small",
  big = "big",
  back = "back",
  secondary = "secondary",
  text = "text",

  // this button type is used only on Apple Health connection
  // screen for mobile devices on button with text "I Opened The App"
  exception = "exception",
}

export interface ButtonProps {
  type: ButtonTypes;
  text: string;
  icon?: string;
  disabled?: boolean;
}

const Button: FunctionComponent<{
  type: ButtonTypes;
  disabled?: boolean;
  text?: string;
  icon?: string;
  clickHandler?: () => void;
}> = ({ type, disabled, text, icon, clickHandler }) => {
  return (
    <button
      className={`
        ${disabled ? styles.disabledWrapper : styles.wrapper} ${
        type === ButtonTypes.big ? styles.big : styles.small
      }`}
      disabled={disabled}
      onClick={clickHandler}
    >
      <ButtonContent
        type={type}
        text={text || ""}
        icon={icon}
        disabled={disabled}
      />
    </button>
  );
};

export default Button;
