import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { generateLink } from "utils/onboarding";
import QRCode from "qrcode.react";
import styles from "./Home.module.scss";
import Button, { ButtonTypes } from "components/Button/Button";
import images from "utils/images";

const Home: FunctionComponent = () => {
  const { t } = useTranslation("translation", { keyPrefix: "home" });

  const handleAppDownload = () => {
    const generatedLink = generateLink();
    window.open(generatedLink, "_blank");
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.centerContainer}>
        <h1 className={styles.title}>{t("title")}</h1>
        <div className={styles.infoContainerFirst}>
          <div className={styles.regularText}>{t("first-info")}</div>
          <br />
          <div className={`${styles.regularText} ${styles.qrInfo}`}>
            {t("qr-info")}
          </div>
          <div className={`${styles.regularText} ${styles.mobileInfo}`}>
            {t("mobile-info")}
          </div>
        </div>

        {generateLink() && (
          <>
            <div className={styles.qrContainer}>
              <QRCode value={generateLink()} size={178} />
            </div>

            <div className={styles.downloadButtonContainer}>
              <Button
                type={ButtonTypes.big}
                text={t("download-button")}
                icon={images.rightArrow}
                clickHandler={handleAppDownload}
              />
            </div>
          </>
        )}

        <div className={styles.infoContainerSecond}>
          <div className={styles.boldText}>{t("continue-info")}</div>
          <br className={styles.additionalBreak} />
          <div className={styles.regularText}>{t("problem-info")}</div>
        </div>
      </div>
    </div>
  );
};

export default Home;
